import {createSelector, createSlice} from "@reduxjs/toolkit";

const initialState = {};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    login(state) {
      state.loginErrors = undefined;
      state.loggingIn = true;
    },
    loginSuccess(state, action) {
      const {token, expiry, sendeunternehmen_id: sendeunternehmenId} = action.payload;
      if (token) {
        state.data = {token, expiry, sendeunternehmenId, active: true};
      }
      delete state.loggingIn;
    },
    loginFailed(state, action) {
      const {errors} = action.payload;
      state.loginErrors = errors;
      delete state.loggingIn;
    },
    logout() {

    },
    logoutSuccess(state) {
      delete state.data;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFailed,
  logout,
  logoutSuccess,
} = sessionSlice.actions;

const reducers = {
  session: sessionSlice.reducer,
};
export default reducers;

const getSessionState = (state) => state.session;

export const isLoggingIn = createSelector(
  getSessionState,
  (sessionState) => sessionState.loggingIn,
);

export const getLoginErrors = createSelector(
  getSessionState,
  (sessionState) => sessionState.loginErrors,
);

export const getSessionData = createSelector(
  getSessionState,
  (sessionState) => sessionState.data || {},
);

export const isLoggedIn = createSelector(
  getSessionData,
  (sessionData) => sessionData.token !== undefined,
);

export const getSessionToken = createSelector(
  getSessionData,
  (sessionData) => sessionData.token,
);

export const isParticipationViewAvailable = createSelector(
  isLoggedIn,
  getSessionData,
  (isLoggedIn, sessionData) => !isLoggedIn || sessionData.sendeunternehmenId !== undefined,
);
