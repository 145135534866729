import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {
  AppBar,
  Collapse,
  CssBaseline,
  Divider,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {Helmet} from "react-helmet";
import {DashboardToolbar} from "./components";
import {useDispatch, useSelector} from "react-redux";
import {closeMainMenu, getMainMenuOpen, getSelectedOrganization, openMainMenu} from "src/features/dashboard";
import {ConfirmationDialog} from "src/components/dialog";
import {HourglassEmpty, PriorityHigh} from "@material-ui/icons";
import SystemStatusBanner from "src/components/dashboard/components/system-status-banner";

const DRAWER_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  bottomBanner: {
    background: 'white',
    position: 'fixed',
    opacity: 0.9,
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    padding: theme.spacing(1),
    zIndex: 1,
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    transition: theme.transitions.create(['margin', 'width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  bottomBannerShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    height: 145,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width', 'height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& > .MuiAlert-root': {
      height: '100%',
    },
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerMiddleSpacer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contentMarginBottom: {
    marginBottom: 145,
  },
}));

export default function DashboardLayout(
  {
    title = "Sendemeldung.de",
    titlePrefix,
    organizationTitle,
    toolbarTitleContent,

    organizationRequiresTests,

    children,

    ToolbarComponent = DashboardToolbar,
    toolbarContent,
    toolbarEndContent,

    drawerContent,
    drawerBottomContent,
  },
) {
  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    has_published_reports: hasPublished,
  } = useSelector(getSelectedOrganization);

  let open = useSelector(getMainMenuOpen);
  useEffect(() => {
    if (narrow && open) {
      // Close drawer when window is narrow.
      dispatch(closeMainMenu());
    }
  }, [narrow]);
  // Default drawer state depends on whether the screen is narrow.
  if (open === undefined) {
    open = !narrow;
  }

  const handleDrawerOpen = () => {
    dispatch(openMainMenu());
  };

  const handleDrawerClose = () => {
    dispatch(closeMainMenu());
  };

  let renderedTitle = title;
  if (organizationTitle !== undefined) {
    renderedTitle = `${organizationTitle} - ${renderedTitle}`;
  }
  if (titlePrefix !== undefined) {
    renderedTitle = `${titlePrefix} - ${renderedTitle}`;
  }

  return (
    <div className={classes.root}>
      <CssBaseline/>

      <Helmet>
        <title>{renderedTitle}</title>
      </Helmet>

      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          {
            [classes.appBarShift]: open,
          },
        )}
      >
        <ToolbarComponent
          title={organizationTitle}
          titleContent={toolbarTitleContent}
          openDrawer={open ? null : handleDrawerOpen}
          right={toolbarEndContent}
        >
          {toolbarContent}
        </ToolbarComponent>
      </AppBar>

      <SwipeableDrawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
          </IconButton>
        </div>

        <Divider/>

        {drawerContent}

        <div className={classes.drawerMiddleSpacer}/>

        {drawerBottomContent ? (
          <>
            <Divider/>
            {drawerBottomContent}
          </>
        ) : null}
      </SwipeableDrawer>

      <main
        className={clsx(
          classes.content,
          {
            [classes.contentShift]: open,
            [classes.contentMarginBottom]: organizationRequiresTests,
          },
        )}
      >
        <div className={classes.drawerHeader}/>

        <noscript>
          <Alert variant="filled" severity="error">
            <AlertTitle>JavaScript erforderlich</AlertTitle>
            Bitte aktivieren Sie JavaScript, um diese Website zu nutzen.
          </Alert>
        </noscript>

        <SystemStatusBanner/>

        {children}
      </main>

      <ConfirmationDialog/>

      {organizationRequiresTests ? (
        <div
          className={clsx(
            classes.bottomBanner,
            {
              [classes.bottomBannerShift]: open,
            },
          )}
        >
          {hasPublished ? (
            <Alert variant="filled" severity="info" icon={<HourglassEmpty/>}>
              <Collapse in={!narrow || open}>
                <AlertTitle>Warte auf Freigabe durch GVL und GEMA</AlertTitle>
                Herzlichen Dank für Ihre Übermittlung einer Beispiel-Sendemeldung, die den Verwertungsgesellschaften
                zur Prüfung vorliegt.
                Wir erwarten in Kürze die Freigabe der Verwertungsgesellschaften, Produktivmeldungen für Ihr
                Sendeunternehmen übermitteln zu dürfen.
                Sobald diese vorliegt, informieren wir Sie per E-Mail.
              </Collapse>
              <Collapse in={narrow && !open}>
                Warte auf Freigabe durch GVL und GEMA<br/>
                Sobald uns die Freigabe zur Übermittlung von Produktivmeldungen für Ihr Unternehmen vorliegt,
                informieren wir Sie per E-Mail.
              </Collapse>
            </Alert>
          ) : (
            <Alert variant="filled" severity="info" icon={<PriorityHigh/>}>
              <Collapse in={!narrow || open}>
                <AlertTitle>Teilnahme an Test erforderlich</AlertTitle>
                Bevor Produktivmeldungen im XML-Format übermittelt werden können, fordern die Verwertungsgesellschaften
                einen erfolgreichen Testdurchlauf.
                Bitte erzeugen Sie hierzu via Sendemeldung.de eine XML-Meldung aus einer bestehenden GEMAGVL4-Datei
                und geben Sie diese zur Prüfung durch GEMA / GVL frei.
              </Collapse>
              <Collapse in={narrow && !open}>
                Teilnahme an Test erforderlich<br/>
                Bitte übermitteln Sie zunächst eine Beispiel-Sendemeldung.
                Nach Prüfung durch GEMA / GVL schalten wir Sie für Produktivmeldungen frei.
              </Collapse>
            </Alert>
          )}
        </div>
      ) : null}

    </div>
  );
}
