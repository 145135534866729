import MuiTextField from "@material-ui/core/TextField";
import React from "react";
import _ from "lodash";
import {Autocomplete} from "@material-ui/lab";
import {Controller} from "react-hook-form";

export default React.forwardRef(
  ({errors = {}, control, name, fullWidth = true, suggestions = [], register, ...props}, ref) => {
    const error = _.get(errors, name);

    return (
      <Controller
        name={name}
        control={control}
        render={({field: {onChange, ...fieldProps}}) => (
          <Autocomplete
            id={name}
            freeSolo
            options={suggestions}
            {...fieldProps}
            onChange={(e, data) => onChange(data)}
            renderInput={(params) => (
              <MuiTextField
                {...props}
                {...params}
                onChange={(e) => onChange(e.target.value)}
                fullWidth={fullWidth}
                error={!!error}
                helperText={error?.message}
              />
            )}/>
        )}
      />
    );
  },
);
