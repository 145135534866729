import {channel} from 'redux-saga';
import {
  actionChannel,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest
} from "redux-saga/effects";
import {
  announceFilteredIdsExcerpt,
  bulkListingAction,
  getFilteredIdsByListingId,
  getFilterIdFromListingId,
  getListing,
  getNumberOfCachedIdsInListing,
  getVisibleIdsForListing,
  updateListing
} from "./listing-slice";
import {endProgress, reportProgress, startProgress} from "../progress";
import axios from "axios";
import {normalize} from "normalizr";
import {logout} from "src/features/session";
import {setEntities} from "src/features/entity";
import {ENTITY_TYPES} from "src/api/api-schemas";

function* doUpdateListing(listingId) {
  try {
    const listing = (yield select(getListing))(listingId);
    const {meta, pageSize, ordering, currentPage, endpoint, entityType} = listing;
    let {count} = listing;
    let first = true;

    while (true) {
      // Check whether there are any ids not yet known.
      const ids = (yield select(getVisibleIdsForListing))(listingId);
      if (!ids.includes(null) && (count !== undefined || listing.count !== undefined) && !first) {
        // Nothing to do. Great.
        return;
      }
      first = false;
      if (count === undefined) {
        count = null;
      }

      const numberOfCachedIds = (yield select(getNumberOfCachedIdsInListing))(listingId);

      // Prepare fetch.
      const filterId = (yield select(getFilterIdFromListingId))(listingId);
      const parameters = {...meta, filterId, pageSize};

      // Respect ordering.
      parameters.ordering = ordering.join(',');

      // Determine missing offset range.
      let startOffset = (currentPage - 1) * pageSize;
      let endOffset = (currentPage - 1) * pageSize + 1;

      if (ids.includes(null)) {
        startOffset += ids.indexOf(null);
        endOffset += ids.lastIndexOf(null);
      } else {
        endOffset += pageSize;
      }

      // Try to determine a cursor that we can use.
      const filteredIds = (yield select(getFilteredIdsByListingId))(listingId);
      let lastOverlappingNext, firstOverlappingPrevious;
      for (const excerpt of filteredIds) {
        if (excerpt === undefined) {
          continue;
        }

        const excerptStartOffset = excerpt.offset;
        const excerptEndOffset = excerptStartOffset + excerpt.results.length;

        const nextStartOffset = excerptEndOffset;
        const nextEndOffset = nextStartOffset + pageSize;

        const prevEndOffset = excerptStartOffset;
        const prevStartOffset = prevEndOffset - pageSize;

        if (Math.min(endOffset, nextEndOffset) - Math.max(nextStartOffset, startOffset) > 0) {
          lastOverlappingNext = excerpt.next;
        } else if (Math.min(endOffset, prevEndOffset) - Math.max(prevStartOffset, startOffset) > 0) {
          if (firstOverlappingPrevious === undefined) {
            firstOverlappingPrevious = excerpt.previous;
          }
        }
      }

      parameters.cursor = lastOverlappingNext || firstOverlappingPrevious;

      if (!parameters.cursor) {
        parameters.page = currentPage;
      }

      // Perform fetch.
      let fetchedEntities;
      try {
        const response = yield call(
          axios.get,
          endpoint,
          {params: {...parameters, page_size: parameters.pageSize}},
        );
        if (!response.data) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("fetch listing entities failed");
        }

        fetchedEntities = response.data;
      } catch (e) {
        console.error(e);
        yield put(updateListing({id: listingId, error: "failed to fetch"}));

        if (e.isAxiosError && e.response?.status === 401) {
          yield put(logout());  // TODO: Generalize error handling.
          break;
        }
        yield delay(500);

        break;
      }
      const results = fetchedEntities.results || [];

      const normalizedEntities = normalize(fetchedEntities?.results, [ENTITY_TYPES[entityType]]);
      yield put(setEntities(normalizedEntities));

      yield put(announceFilteredIdsExcerpt({
        filterId,
        offset: startOffset,
        cursor: parameters.cursor,
        ...fetchedEntities,
      }));

      count = fetchedEntities.count;
      if (count !== undefined && count !== null && count !== listing.count) {
        // Counter changed. This implies that this task is re-executed.
        yield put(updateListing({id: listingId, count}));
      } else if ((yield select(getNumberOfCachedIdsInListing))(listingId) !== numberOfCachedIds) {
        // Update listing whenever we made progress so that we can continue if needed.
        yield delay(500);
        continue;
      } else if (results.length < Math.min(pageSize, listing.count - startOffset)) {
        // We received fewer results than expected, so we might need to fetch more items.
        continue;
      }

      break;
    }
  } catch (e) {
    console.error(e);
    if (!(yield cancelled())) {
      yield put(updateListing({id: listingId, error: "error"}));
      throw e;
    }
  } finally {
    if (yield cancelled()) {
      // Nothing to do.
    }
  }
}

/**
 * Creator for a saga that manages all actions that involve a specific listing.
 */
const createListingSaga = ({id}) => function* (chan) {
  let lastUpdateListingTask;
  let entityType;
  while (true) {
    const {listingAction, setEntitiesAction} = yield race({
      listingAction: take(chan),
      setEntitiesAction: take([setEntities]),
    });

    if (listingAction) {
      const {type, payload} = listingAction;

      if (payload.entityType) {
        entityType = payload.entityType;
      }

      if (type === updateListing.type && !payload.error) {
        // Only the latest updateListing task should run.
        if (lastUpdateListingTask) {
          yield cancel(lastUpdateListingTask);
        }
        lastUpdateListingTask = yield fork(doUpdateListing, id);
      }
    } else if (setEntitiesAction) {
      const {entities} = setEntitiesAction?.payload;
      const relevantAffectedEntities = entities?.[`${entityType}s`];
      // Here we could react to changes, e.g., entity deletions.
    }
  }
};

/**
 * Saga that manages all actions that involve listings.
 *
 * For each listing, an individual saga is started that organizes the listing's content.
 */
function* listingsManager() {
  const listingActionsChan = yield actionChannel([updateListing]);

  const listingSagas = {};
  while (true) {
    const action = yield take(listingActionsChan);
    const {id} = action.payload;

    let listingSaga = listingSagas[id];

    // Start listing-specific saga if necessary.
    if (!listingSaga) {
      const chan = yield call(channel);
      listingSagas[id] = listingSaga = {
        chan,
        task: yield fork(createListingSaga({id}), chan),
      };
    }

    // Forward action to listing's individual saga.
    yield put(listingSaga.chan, action);
  }
}

function* watchBulkListingAction(action) {
  const {
    ids,
    actionTemplate,
    actionIdField,
    progressType,
    listingId,
    progressMessageTemplate,
    totalFailureMessageTemplate
  } = action.payload;

  const progress = {type: bulkListingAction.type, id: listingId};
  const total = ids.length;
  yield put(startProgress({
    ...progress,
    total,
    successful: 0,
    blockUnload: true,
    progressMessageTemplate,
    totalFailureMessageTemplate
  }));

  let observedErrors = 0;

  for (const [i, id] of ids.entries()) {
    const action = {
      ...actionTemplate,
      payload: {
        ...actionTemplate.payload,
        [actionIdField]: id,
      },
    };

    yield put(action);

    if (progressType) {
      // Wait for action to finish.
      while (true) {
        const {payload} = yield take(endProgress);
        if (payload.type === progressType && payload.id === id) {
          if (payload.error) {
            observedErrors++;
          }
          break;
        }
      }
    }

    yield put(reportProgress({...progress, progress: i + 1, successful: i + 1 - observedErrors}));
  }

  let finalProgress;
  if (observedErrors > 0) {
    finalProgress = {...progress, error: true, successful: total - observedErrors, progress: total};
  } else {
    finalProgress = {...progress, success: true, successful: total - observedErrors};
  }

  yield put(endProgress({...finalProgress, recent: true}));
  yield delay(observedErrors > 0 ? 5000 : 2000);
  yield put(endProgress({...finalProgress, recent: false}));
}

export default function* listingSaga() {
  yield spawn(listingsManager);

  yield takeLatest(bulkListingAction, watchBulkListingAction);
}
