import {Divider, List, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import {Email, Feedback} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import logo from "src/components/layout/sendemeldung_de.svg";
import Typography from "@material-ui/core/Typography";
import {MenuListItem} from "src/components/dashboard/components/drawer-main-menu-view";
import {Link} from "gatsby-theme-material-ui";
import HrefMenuListItem from "src/packages/gatsby-mui-helpers/HrefMenuListItem";

const useStyles = makeStyles((theme) => ({
  drawerFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  logo: {
    padding: theme.spacing(1, 3),
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function DrawerBottomMenu({selected, feedbackOpen, openFeedback}) {
  const classes = useStyles();

  return (
    <>
      <List>
        <HrefMenuListItem button href={'/dashboard/contact/'} active={selected === 'service'}>
          <ListItemIcon><Email/></ListItemIcon>
          <ListItemText primary="Service kontaktieren"/>
        </HrefMenuListItem>
        <MenuListItem button onClick={openFeedback} active={feedbackOpen}>
          <ListItemIcon><Feedback/></ListItemIcon>
          <ListItemText primary="Feedback &amp; Bugs"/>
        </MenuListItem>
      </List>
      <Divider/>
      <div className={classes.drawerFooter}>
        <div>
          <img src={logo} alt="Sendemeldung.de" className={classes.logo}/><br/>
          <Typography paragraph align="center">
            <small>
              Vorläufiger Betrieb<br/>
              <Link to="/dashboard/changelog/" style={{textDecoration: 'none'}} color="inherit">
                Version 0.1.1-alpha
              </Link>
            </small>
          </Typography>
        </div>
      </div>
    </>
  );
}
