import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const visibilityTrackerSlice = createSlice({
  name: 'visibilityTracker',
  initialState: {
    visible: true,
  },
  reducers: {
    setVisible(state, action) {
      state.visible = action.payload;
    },
  },
});

export const {setVisible} = visibilityTrackerSlice.actions;

export default {
  visibilityTracker: visibilityTrackerSlice.reducer,
};

export const getVisibilityTracker = createSelector(
  getUI,
  ui => ui.visibilityTracker,
);

export const isDocumentVisible = createSelector(
  getVisibilityTracker,
  visibilityTracker => visibilityTracker.visible,
);
