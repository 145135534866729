import progressReducers from './progress-slice';

const reducers = {
  ...progressReducers,
};
export default reducers;

export * from './progress-sagas';
export * from './progress-slice';

export {default as saga} from './progress-sagas';
