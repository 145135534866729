import entityReducers from './entity-slice';

const reducers = {
  ...entityReducers,
};
export default reducers;

export {default as saga} from './entity-sagas';

export * from './entity-slice';
