import {connect} from "react-redux";
import {isLoggedIn, logout} from "src/features/session";
import WelcomeDashboardLayout from "./welcome-dashboard-layout-view";

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomeDashboardLayout);
