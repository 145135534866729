import axios from "axios";

export class ApiError extends Error {
  constructor(response) {
    super("ApiError");
    this.response = response;
    this.data = response?.data;
    this.name = "ApiError";
  }
}

export class ValidationError extends ApiError {
  constructor(response) {
    super(response);
    this.name = "ValidationError";
  }
}

export class AuthenticationError extends ApiError {
  constructor(response) {
    super(response);
    this.name = "AuthenticationError";
  }
}

const makeApiCall = (apiCallFn) => async (url, ...args) => {
  let response;
  try {
    response = await apiCallFn(url, ...args);
  } catch (e) {
    console.error(e);
    const data = e.response?.data;
    if (e.isAxiosError && e.response?.status === 401) {
      throw new AuthenticationError(e.response);
    } else if (data) {
      throw new ValidationError(e.response);
    } else if (e.isAxiosError) {
      throw new ApiError(e.response);
    } else {
      throw e;
    }
  }

  return response;
};

export const api = {
  get: makeApiCall(axios.get),
  patch: makeApiCall(axios.patch),
  post: makeApiCall(axios.post),
  delete: makeApiCall(axios.delete),
};
