import {createMuiTheme, responsiveFontSizes} from '@material-ui/core';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=ef6c00&secondary.color=1976D2
    primary: {
      main: '#ef6c00',
      light: '#ff9d3f',
      dark: '#b53d00',
    },
    secondary: {
      main: '#1976d2',
      light: '#63a4ff',
      dark: '#004ba0',
    },

    warning: {
      main: '#ef6c00',
      light: '#ff9d3f',
      dark: '#b53d00',
    },
    info: {
      main: '#999999',
    },

    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
  },

  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9rem',
        // backgroundColor: 'black',
      },
      arrow: {
        // color: 'black',
      },
    },
  },
});

export default responsiveFontSizes(theme);
