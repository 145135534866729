import dashboardReducers from './dashboard-slice';

const reducers = {
  ...dashboardReducers,
};
export default reducers;

export {default as saga} from './dashboard-sagas';

export * from './dashboard-slice';
