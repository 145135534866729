import {IconButton, Toolbar} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function DashboardToolbar({title, titleContent, openDrawer, children, right}) {
  const classes = useStyles();

  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={openDrawer}
        edge="start"
        className={clsx(
          classes.menuButton,
          {
            [classes.hide]: !openDrawer,
          },
        )}
      >
        <MenuIcon/>
      </IconButton>
      {(title && !titleContent) ? (
        <Typography variant="h6" noWrap className={classes.title}>
          {title}
        </Typography>
      ) : null}
      {titleContent}
      {children}
      <div className={classes.spacer}/>
      {right}
    </Toolbar>
  );
}
