import * as yup from "yup";
import yupLocaleDe from 'src/packages/yup-locale-de';

import _ from 'lodash';
import {ENCODINGS} from "src/packages/encodings";
import {TIMEZONES} from "src/packages/timezones";
import {formatISO} from "date-fns";

yup.setLocale(yupLocaleDe);

export const MUSIK_HERKUNFT_KNZ = {
  EIGEN: "Eigenproduktion",
  AUFTRAG: "Auftragsproduktion",
  LIVE: "Live",
  VTON: "veröffentlichte Tonaufnahme",
  PLM: "Production Music Library",
};

export const CONTACT_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organizations: yup.array(yup.string()),
  salutation: yup.string()
    .label("Anrede")
    .meta({
      options: {
        frau: "Frau",
        herr: "Herr",
        '': "(neutral)",
      },
      optionKeyBindings: {
        f: 'frau',
        h: 'herr',
      },
      autoComplete: 'sex',
    })
    .trim()
    .max(255)
    .nullable(),
  title: yup.string()
    .label("Titel")
    .meta({
      autoComplete: 'honorific-prefix',
    })
    .trim()
    .max(255)
    .nullable(),
  name: yup.string()
    .label("Name")
    .meta({
      autoComplete: 'name',
    })
    .trim()
    .max(255)
    .nullable(),
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .max(255)
    .nullable()
    .when('set_password', (setPassword, schema) => {
      return setPassword ? schema.required("Die Login-Berechtigung erfordert eine E-Mail-Adresse.") : schema;
    }),
  set_password: yup.boolean().default(false),
  new_username: yup.ref('email'),
  new_password: yup.string()
    .label("Passwort")
    .max(255)
    .notRequired()
    .meta({password: true})
    .when('set_password', (setPassword, schema) => {
      return setPassword ? (
        schema.min(8, "Passwort ist zu kurz (min. 8 Zeichen benötigt)")
      ) : schema.transform(() => null);
    }),
  passwordConfirmation: yup.string()
    .label("Passwort (Wiederholung)")
    .max(255)
    .notRequired()
    .meta({password: true})
    .when('set_password', (setPassword, schema) => {
      return setPassword ? (
        schema.oneOf([yup.ref('new_password'), null], "Passwörter müssen übereinstimmen.")
      ) : schema.transform(() => null);
    }),
});

export const CHANGE_CONTACT_PASSWORD_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  old_password: yup.string()
    .label("Bisheriges Passwort")
    .max(255)
    .required()
    .meta({password: true}),
  new_password: yup.string()
    .label("Neues Passwort")
    .max(255)
    .required()
    .meta({password: true})
    .min(8, "Passwort ist zu kurz (min. 8 Zeichen benötigt)"),
  passwordConfirmation: yup.string()
    .label("Neues Passwort (Wiederholung)")
    .max(255)
    .required()
    .meta({password: true})
    .oneOf([yup.ref('new_password'), null], "Passwörter müssen übereinstimmen."),
});

export const REGISTER_USER_SCHEMA = yup.object().shape({
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .required()
    .max(255),
  username: yup.ref('email'),
  password: yup.string()
    .label("Passwort")
    .max(255)
    .required()
    .min(8, "Passwort ist zu kurz (min. 8 Zeichen benötigt)")
    .meta({password: true}),
  passwordConfirmation: yup.string()
    .label("Passwort (Wiederholung)")
    .max(255)
    .required()
    .oneOf([yup.ref('password'), null], "Passwörter müssen übereinstimmen.")
    .meta({password: true}),
});

export const ORGANIZATION_CONTACT_ROLE_SCHEMA = yup.object().shape({
  contact: CONTACT_SCHEMA,
  organization: yup.string().nullable(),
  is_gl: yup.boolean()
    .label("Ansprechpartner für Geschäftsleitung"),
  is_technik: yup.boolean()
    .label("Ansprechpartner Technik/Musikredaktion"),
  is_lira: yup.boolean()
    .label("Ansprechpartner für LIRA"),
});

export const STATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  gemagvl_sender_prg_id: yup.string().nullable()
    .label("Programm-Kennzeichen für XML-Meldungen (SENDER_PRG_ID)"),
  name: yup.string().nullable()
    .label("Programm-Name"),
  gemagvl4_sender: yup.string().nullable()
    .label("SENDER laut GEMAGVL4"),
  gemagvl4_prognr: yup.number("PROGNR muss eine Zahl sein.")
    .transform((value, originalValue) => ((!originalValue && _.isNaN(value)) ? undefined : value))
    .default(0)
    .integer()
    .min(0)
    .max(9)
    .meta({
      options: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
      },
    })
    .label("PROGNR laut GEMAGVL4"),
  report_jingles: yup.boolean()
    .nullable()
    .label("Jingle-Meldungen aktivieren"),
  max_overlapping_seconds: yup.number("Wert muss eine Zahl sein")
    .transform((value, originalValue) => ((!originalValue && _.isNaN(value)) ? null : value))
    .nullable()
    .integer()
    .min(0)
    .label("Maximale Crossfading-/Überlappungszeit (Sekunden)"),
});

export const MUSIK_PRODUKTION_ID_TYP_KNZ = {
  ISRC: "ISRC",
  EAN_UPC: "EAN/UPC",
  KATALOG_NR: "Katalog- / Bestellnummer",
  GEMA_WERK_NR: "GEMA-Werknummer",
  ICE_WORK_KEY: "ICE",
  GVL_PRODUKT_ID: "GVL-Produkt-ID",
  MPN_ID: "MPN-ID",
  GRID: "GRID",
  ISWC: "ISWC",
  MUSIK_ARCHIV_NR: "Archivnummer",
};

export const DEKLARATION_TONAUFNAHME_KNZ = {
  LABLC: "Label mit Labelcode",
  LABEL: "Label ohne Labelcode",
  PROMO: "Promo-CD",
  SELFRELEASED: "self-released",
  OHNE: "ohne Deklaration",
};

export const MUSIC_PRODUCTION_ID_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  id_typ_knz: yup.string()
    .label("Typ")
    .meta({
      options: {
        ...MUSIK_PRODUKTION_ID_TYP_KNZ,
      },
    })
    .nullable(),
  id_wert: yup.string()
    .label("ID")
    .trim()
    .max(250)
    .nullable(),
});

export const ORGANIZATION_DETAILS_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  company_name: yup.string()
    .label("Firmenname")
    .meta({
      autoComplete: 'company-name',
    })
    .trim()
    .max(255)
    .nullable(),
  display_name: yup.string()
    .label("Anzeigename")
    .meta({
      autoComplete: 'display-company-name',
      helperText: "Optionale abweichende Schreibweise Ihres Firmennamens für die Anzeige in der Titelzeile.",
    })
    .trim()
    .max(255)
    .nullable(),
  address: yup.string()
    .label("Adresse")
    .meta({
      autoComplete: 'address',
      rows: 7,
    })
    .trim()
    .max(255)
    .nullable(),
  gemagvlxml_lieferant_id: yup.string()
    .label("GEMAGVL-XML-Lieferant-ID")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  jingle_musik_herkunft_knz: yup.string()
    .label("Musikherkunft Ihrer Jingles")
    .meta({
      options: {
        null: "(keine Angabe)",
        ...MUSIK_HERKUNFT_KNZ,
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  gemagvl4_encoding_preferences: yup.array()
    .label("GEMAGVL4-Zeichensatz")
    .meta({
      options: {
        auto: "(automatische Erkennung)",
        ...Object.fromEntries(ENCODINGS.map((x) => ([x, x]))),
      },
      autoComplete: 'charset',
    })
    .transform((value, originalValue) => (
      originalValue === 'null' ? (
        undefined
      ) : originalValue === 'auto' || !originalValue ? (
        null
      ) : typeof originalValue === 'string' ? (
        [originalValue]
      ) : originalValue
    ))
    .nullable(),
  gemagvl4_timezone: yup.string()
    .label("GEMAGVL4-Zeitzone")
    .meta({
      options: {
        ...TIMEZONES,
      },
      autoComplete: 'charset',
    })
    .nullable(),
});

export const ERSCHIENENE_TONAUFNAHME_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  deklaration_tonaufnahme_knz: yup.string()
    .label("Deklaration Tonaufnahme")
    .meta({
      options: {
        ...DEKLARATION_TONAUFNAHME_KNZ,
      },
    })
    .nullable(),
  label: yup.string()
    .label("Label")
    .trim()
    .max(50)
    .nullable(),
  label_code: yup.number("Label-Code muss eine Zahl sein")
    .transform((value, originalValue) => ((!originalValue && _.isNaN(value)) ? null : value))
    // .default(0)
    .nullable()
    .integer()
    .min(0)
    .max(99999999)
    .label("Label-Code"),
  album_titel: yup.string()
    .label("Album-Titel")
    .trim()
    .max(250)
    .nullable(),
  voe_datum: yup.string().meta({date: true}).label("Datum der Veröffentlichtung").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue, {representation: 'date'});
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
  sonstige_info: yup.string()
    .label("sonstige Informationen")
    .trim()
    .max(250)
    .nullable(),
});

export const MUSIK_PERSON_ROLLE_KNZ = {
  K: "Komponist",
  B: "Bearbeiter",
  SB: "Subbearbeiter",
  T: "Texter",
  ST: "Subtextdichter",
  TS: "Spezialtextdichter",
  V: "Verlag",
  OV: "Originalverlag",
  SV: "Subverlag",
  INT: "Interpret",
  DIRIG: "Dirigent",
  ENS: "Ensemble",
  GRUPPE: "Gruppe",
  ORCH: "Orchester",
  SON: "sonstige Person",
};

export const RECHT_KNZ = {
  KL: "Kleines Recht",
  GR: "Großes Recht",
};

export const NUTZUNG_ART_KNZ = {
  STANDARD: "Standard",
  JINGLE: "Jingle",
};

export const BESETZUNG_KNZ = {
  INSTR_2: "Instrumentalwerk mit 1-2 Instrumentalstimmen",
  INSTR_9: "Instrumentalwerk mit 3-9 Instrumentalstimmen",
  CHOR_KL: "kleiner Chor",
  CHOR_GR: "großer Chor",
  KLEINES_ORCH: "kleines Orchester",
  GROSSES_ORCH: "großes Orchester",
  ELEKTRO: "elektronische Musik",
  BUEHNE: "Bühnenmusik",
};

export const MUSIC_PERSON_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  name: yup.string()
    .label("Name")
    .trim()
    .max(90)
    .required(),
  vorname: yup.string()
    .label("Vorname")
    .trim()
    .max(45)
    .nullable(),
  musik_person_rolle_knz: yup.string()
    .label("Rolle")
    .meta({
      options: {
        ...MUSIK_PERSON_ROLLE_KNZ,
      },
    })
    .nullable(),
});

export const MUSIC_TITLE_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  musik_titel: yup.string()
    .label("Titel")
    .trim()
    .max(250)
    .nullable(),
});

export const MUSIC_WORK_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  musik_titel: yup.string()
    .label("Titel")
    .trim()
    .max(250)
    .nullable(),
  recht_knz: yup.string()
    .label("Recht")
    .meta({
      options: {
        ...RECHT_KNZ,
      },
    }),
  besetzung_knz: yup.string()
    .label("Besetzung")
    .meta({
      options: {
        null: "(leer)",
        ...BESETZUNG_KNZ,
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  gattung: yup.string()
    .label("Gattung")
    .trim()
    .max(250)
    .nullable(),
  nutzung_art_knz: yup.string()
    .label("Nutzungsart")
    .meta({
      options: {
        ...NUTZUNG_ART_KNZ,
      },
    })
    .nullable(),
  is_silence: yup.boolean()
    .label("Musikwerk ist Platzhalter- / Stille-Datei"),
  aufnahme_datum: yup.string().meta({datetime: true}).label("Aufnahmedatum").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue);
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
  prod_titel: yup.string()
    .label("Name der Produktion")
    .trim()
    .max(250)
    .nullable(),
  prod_ort: yup.string()
    .label("Ort der Aufführung / Produktion")
    .trim()
    .max(250)
    .nullable(),
  musik_herkunft_knz: yup.string()
    .label("Musikherkunft")
    .meta({
      options: {
        null: "(keine Angabe)",
        ...MUSIK_HERKUNFT_KNZ,
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  // erschienene_tonaufnahme: ERSCHIENENE_TONAUFNAHME_SCHEMA,
});

export const GEMAGVLXML_AUSSTRAHLUNG_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  gemagvlxml_lieferung: yup.string().nullable(),
  datum_uhrzeit_von: yup.string().meta({datetime: true}).label("Ausstrahlungsbeginn").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue);
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
  datum_uhrzeit_bis: yup.string().meta({datetime: true}).label("Ausstrahlungsende").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue);
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
});

export const FEEDBACK_SCHEMA = yup.object().shape({
  name: yup.string().nullable()
    .label("Name"),
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .max(255)
    .nullable(),
  message: yup.string()
    .label("Feedback")
    .meta({
      autoComplete: 'message',
      rows: 7,
    })
    .trim()
    .max(16384)
    .required(),
});
