import {useHasMounted} from "src/packages/has-mounted-hook";
import {useForm, useFormContext} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCallback, useEffect} from "react";

export function useYupForm({schema, data, errors, submit, shouldUnregister = false, ...formProps}) {
  const hasMounted = useHasMounted();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: data,
    shouldUnregister,
    ...formProps,
  });
  const {handleSubmit, setError, clearErrors} = form;

  const submitAction = useCallback((event) => {
    event.preventDefault();
    return handleSubmit(submit)();
  }, [handleSubmit, submit]);

  useEffect(() => {
    if (!hasMounted) {
      // If we run clearErrors before the fields are registered, weird things happen.
      return;
    }
    if (!errors) {
      // TODO: Reset form submit state.
      clearErrors();
      return;
    }
    Object.entries(errors).forEach(([field, error]) => {
      setError(field, {type: 'manual', message: error}, {shouldFocus: true});
    });
  }, [errors, clearErrors, setError, hasMounted]);

  return {form, hasMounted, submitAction};
}
