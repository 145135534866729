import {combineReducers} from "@reduxjs/toolkit";
import confirmationReducer from "./confirmation";
import listingReducer from "./listing";
import preferencesReducer from "./preferences";
import progressReducer from "./progress";
import unloadBlocker from "./unload-blocker";
import viewReducer from "./view";
import visibilityTrackerReducer from "./visibility-tracker";

export default {
  ui: combineReducers({
    ...confirmationReducer,
    ...listingReducer,
    ...preferencesReducer,
    ...progressReducer,
    ...unloadBlocker,
    ...viewReducer,
    ...visibilityTrackerReducer,
  }),
};
