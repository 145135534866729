import React from "react";
import _ from "lodash";
import {FormControl, FormHelperText} from "@material-ui/core";
import {Controller} from "react-hook-form";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {formatISO} from "date-fns";

export default function DateTimeView(
  {
    errors = {},
    control,
    name,
    variant,
    fullWidth = true,
    label,
    items = {},
    margin,
    onBlur,
    ...props
  },
) {
  const error = _.get(errors, name);

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      margin={margin}
      error={!!error}
      onBlur={onBlur}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({field: {onChange, ...field}}) => (
          <KeyboardDateTimePicker
            label={label}
            inputProps={{
              id: name,
            }}
            ampm={false}
            format="yyyy-MM-dd HH:mm:ss XXX"
            cancelLabel="Abbrechen"
            mask="____-__-__ __:__:__ +__:__"
            views={["hours", "minutes", "seconds"]}
            onChange={(date, value) => {
              try {
                value = formatISO(date);
              } catch (e) {
                // value = null;
              }
              return onChange(date, value);
            }}
            {...props}
            {...field}
          />
        )}
      />
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  );
}
