import {isLoggedIn} from "src/features/session";
import {useSelector} from "react-redux";
import {navigate} from "gatsby";

export function useLoggedIn() {
  const loggedIn = useSelector(isLoggedIn);
  if (!loggedIn) {
    navigate('/');
  }
}
