import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles} from '@material-ui/core/styles';
import {Helmet} from "react-helmet";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '100%',
    minHeight: '100vh',
  },
}));

export default function GlobalLayout({children}) {
  const classes = useStyles();

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div
      role="application"
      className={classes.root}
      onDragStart={(event) => {
        event.preventDefault();
      }}
      onDragOver={(event) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'none';
      }}
    >
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Sendemeldung.de</title>
      </Helmet>
      <CssBaseline/>
      {children}
    </div>
  );
}
