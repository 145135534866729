import React from 'react';
import {TablePagination} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {updateListing} from "src/features/ui/listing";

export default function ({currentPage, totalPages, count, setPage, setPageSize, pageSize, force, error, listingId}) {
  if (currentPage === undefined) {
    return null;
  }

  if (currentPage === 1 && totalPages === 1 && !force) {
    return null;
  }

  const handleChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const dispatch = useDispatch();

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    if(setPageSize) {
      setPageSize(rowsPerPage);
    } else {
      dispatch(updateListing({
        id: listingId,
        pageSize: rowsPerPage,
        currentPage: 1,
      }));
    }
  };

  return (
    <TablePagination
      component="div"
      labelRowsPerPage="Einträge pro Seite:"
      backIconButtonText="Vorherige Seite"
      nextIconButtonText="Nächste Seite"
      labelDisplayedRows={({from, to, count}) => `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`}
      count={count === undefined ? -1 : count}
      page={currentPage - 1}
      onChangePage={handleChange}
      rowsPerPage={pageSize}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
