import sessionReducers from './session';
import participationReducers from './participation';
import dashboardReducers from './dashboard';
import entityReducers from './entity';
import ormReducers from './orm';
import uiReducers from './ui';

const reducers = {
  ...sessionReducers,
  ...dashboardReducers,
  ...entityReducers,
  ...ormReducers,
  ...participationReducers,
  ...uiReducers,
};
export default reducers;
