import {createSelector} from 'redux-orm';
import orm from './orm';

export const uploadedFilesSelector = createSelector(
  orm,
  session => {
    return session.UploadedFile;
  },
);

export const uploadedFiles = createSelector(orm.UploadedFile);

// export const selectOrganizations = createSelector(orm.Organization.all().orderBy('render_name'));

export const selectOrganizations = createSelector(
  orm,
  session => session.Organization.all().orderBy('render_name'),
);
