import {all, take} from 'redux-saga/effects';
import {REHYDRATE} from 'redux-persist/lib/constants';
import {saga as sessionSaga} from './session';
import {saga as participationSaga} from './participation';
import {saga as dashboardSaga} from './dashboard';
import {saga as entitySaga} from './entity';
import {saga as uiSaga} from './ui';

export default function* rootSaga() {
  yield take(REHYDRATE);
  yield all([
    sessionSaga(),
    dashboardSaga(),
    entitySaga(),
    participationSaga(),
    uiSaga(),
  ]);
}
