import React from 'react';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {PersistGate as PersistGateClient} from 'redux-persist/integration/react'
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';

import featureSaga from "../../features/sagas";
import createStore from '../../state/createStore';
import log from "loglevel";

class PersistGateServer extends React.Component {
  render() {
    return this.props.children;
  }
}

export default function TopLayout({children, theme}) {
  const sagaMiddleware = createSagaMiddleware({
    onError(error, {sagaStack}) {
      log.error({error, errorType: typeof error, sagaStack});
    },
  });
  const {store, persistor} = createStore(sagaMiddleware);

  sagaMiddleware.run(featureSaga);

  const PersistGate = (typeof window === 'undefined') ? PersistGateServer : PersistGateClient;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
      </PersistGate>
    </Provider>
  );
}
