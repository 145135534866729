import {Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import React, {useState} from "react";
import {AccountCircle, ExitToApp, Person, VpnKey} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "src/features/session";
import {navigate} from "gatsby";
import {hasProfileData} from "src/features/participation";
import {getSelectedOrganization} from "src/features/dashboard";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {getContactGetter, getContactRoleGetter} from "src/features/entity";
import {ChangePasswordForm} from "src/components/entities/person/ChangePasswordForm";

export default function ProfileButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileOpen = Boolean(anchorEl);

  const hasProfile = useSelector(hasProfileData);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    closeMenu();
  };

  const {request_user_contact_role} = useSelector(getSelectedOrganization);

  useEntityObserver({type: 'contact_role', id: request_user_contact_role});

  const {contact} = useSelector(getContactRoleGetter)(request_user_contact_role);

  useEntityObserver({type: 'contact', id: contact});

  const {id, organization, username, email} = useSelector(getContactGetter)(contact);

  const [editPassword, setEditPassword] = useState(null);

  return (
    <>
      {username ? (
        <Button
          startIcon={<AccountCircle/>}
          aria-label="account of current user"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={openMenu}
          color="inherit"
        >
          {username}
        </Button>
      ) : (
        <IconButton
          aria-label="account of current user"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={openMenu}
          color="inherit"
        >
          <AccountCircle/>
        </IconButton>
      )}
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={profileOpen}
        onClose={closeMenu}
      >
        {/*<MenuItem onClick={closeMenu}>Mein Konto</MenuItem>*/}
        {hasProfile ? (
          <MenuItem onClick={
            typeof window !== "undefined" ? (
              () => window.location.href = '/teilnahme/dashboard'
            ) : (
              () => navigate('/teilnahme/dashboard')
            )}>
            <ListItemIcon>
              <Person fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              zur Teilnahme-Verwaltung
            </ListItemText>
          </MenuItem>
        ) : null}
        {hasProfile ? (
          <Divider/>
        ) : null}
        {username ? (
          <MenuItem
            onClick={() => {
              setEditPassword({id, organization, email, new_username: username});
              closeMenu();
            }}
          >
            <ListItemIcon>
              <VpnKey fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              Passwort ändern...
            </ListItemText>
          </MenuItem>
        ) : null}
        {username ? (
          <Divider/>
        ) : null}
        <MenuItem
          onClick={() => {
            handleLogout();
            closeMenu();
          }}
        >
          <ListItemIcon>
            <ExitToApp fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu>

      {/*TODO: Delayed removal of dialog for fadeout transition.*/}
      {editPassword ? (
        <ChangePasswordForm
          data={editPassword}
          onClose={() => setEditPassword(null)}
        />
      ) : null}
    </>
  );
}
