import React from 'react';
import {GlobalLayout} from "src/components/layout";

export const wrapPageElement = ({element, props}) => (
  <GlobalLayout {...props}>
    {element}
  </GlobalLayout>
);

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== 'production') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      // trackAllPureComponents: true,
    });
  }
};
