import {Button, IconButton} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useFormContext} from "react-hook-form";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  iconButtonWrapper: {
    margin: theme.spacing(0),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export function ProgressButton({children, Component = Button, inProgress, disabled, className, color='inherit', ...props}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.buttonWrapper)}>
      <Component
        disabled={disabled || inProgress}
        color={color}
        {...props}
      >
        {children}
      </Component>
      {inProgress ? (
        <CircularProgress size={24} className={classes.buttonProgress}/>
      ) : null}
    </div>
  );
}

export function ProgressIconButton({children, Component = IconButton, inProgress, disabled, className, color='inherit', ...props}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.iconButtonWrapper)}>
      <Component
        disabled={disabled || inProgress}
        color={color}
        {...props}
      >
        {children}
      </Component>
      {inProgress ? (
        <CircularProgress size={24} className={classes.buttonProgress}/>
      ) : null}
    </div>
  );
}

export default function FormSubmitButton({children, ...props}) {
  const {formState: {isSubmitting}} = useFormContext();

  return (
    <ProgressButton
      type="submit"
      inProgress={isSubmitting}
      {...props}
    >
      {children}
    </ProgressButton>
  );
}
