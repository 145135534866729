import {all, call, delay, put, select, take} from 'redux-saga/effects';
import {getSessionData, isLoggedIn, login, loginFailed, loginSuccess, logout, logoutSuccess} from './session-slice';
import axios from 'axios';
import _ from 'lodash';

function* loginSaga() {
  // Wait for login.
  let failedRecently = false;
  while (true) {
    const action = yield take([login]);

    // Process login.
    const {username, password, accessToken} = action.payload;

    const loginEndpoint = accessToken ? '/api/teilnahme/auth/login/' : '/api/sendemeldung/auth/login/';

    try {
      const response = yield call(axios.post, loginEndpoint,
        (accessToken ? {
          username: '$welcome_access_token',
          password: accessToken,
        } : {
          username,
          password,
        }));
      if (!response.data) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("submit failed");
      }

      yield put(loginSuccess(response.data));
      failedRecently = false;
      break;
    } catch (e) {
      if (failedRecently) {
        yield delay(500);  // Minimal delay for the user's convenience.
      }

      let errors = e?.response?.data;
      if (!_.isObject(errors)) {
        errors = {non_field_errors: "Ein unerwarteter Fehler ist aufgetreten."};
      }
      if (errors.non_field_errors && errors.non_field_errors[0] === 'Die angegebenen Zugangsdaten stimmen nicht.' && accessToken) {
        errors.accessToken = '';
        errors.non_field_errors[0] = "Der angegebene Zugangscode ist ungültig.";
      }
      yield put(loginFailed({errors}));
      failedRecently = true;
    }
  }
}

function* logoutSaga() {
  // Wait for logout.
  yield take([logout]);

  // Process logout.
  try {
    yield call(axios.post, '/api/auth/logout/');
  } catch (e) {
    console.error(e);
  } finally {
    axios.defaults.headers.common = {}
    yield put(logoutSuccess());
  }
}

function* loginLogoutSaga() {
  while (true) {
    if (!(yield select(isLoggedIn))) {
      yield call(loginSaga);
    }
    yield call(logoutSaga);
  }
}

function* configureAxios() {
  while (true) {
    const {token} = yield select(getSessionData);
    if (token) {
      axios.defaults.headers.common = {'Authorization': `Token ${token}`}
    }
    yield take([loginSuccess]);
  }
}

export default function* rootSaga() {
  yield all([
    call(loginLogoutSaga),
    call(configureAxios),
  ]);
}
