import {hasUnloadBlockers} from ".";

export function addEventHandlers(store, options = {}) {
  let {target} = options;

  if (typeof window === "undefined") {
    return store;
  }

  if (target === undefined) {
    target = window;
  }

  if (target === undefined) {
    console.warning("Could not add unloadBlocker event listeners.");
    return store;
  }

  target.addEventListener('beforeunload', event => {
    const state = store.getState();
    if (hasUnloadBlockers(state)) {
      event.preventDefault();
      event.returnValue = "Beim Schließen dieser Seite werden laufende Aktionen abgebrochen.";
      return event.returnValue;
    }
  });

  return store;
}
