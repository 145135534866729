import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  DialogContent,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {FEEDBACK_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import {makeStyles} from "@material-ui/core/styles";
import {BugReport, ExpandMore} from "@material-ui/icons";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {USER_FEEDBACK} from "src/api/api-schemas";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {
  getInvisibleCreatedIdsByOrganizationForListing,
  getListing,
  getVisibleIdsForListing,
  updateListing
} from "src/features/ui/listing";
import {getKnownBugGetter} from "src/features/entity";
import {Pagination} from "src/components/paginator";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Feedback(
  {
    open,
    onClose,
    ...props
  }
) {
  const [feedback, setFeedback] = useState({});

  const classes = useStyles();

  const listingId = `known_bugs`;

  const dispatch = useDispatch();
  const listing = useSelector(getListing)(listingId);

  useEffect(() => {
    if (selectedOrganizationId && listingId) {
      dispatch(updateListing({
        id: listingId,
        currentPage: 1,
        pageSize: 10,
        ordering: [],
        searchQuery: '',
        endpoint: `/api/sendemeldung/known_bugs/`,
        entityType: 'known_bug',
      }));
    } else {
      // TODO: Clear listing (and/or do that in unmount effect).
    }
  }, [listingId]);

  const getKnownBug = useSelector(getKnownBugGetter);

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);
  const createdIds = useSelector(getInvisibleCreatedIdsByOrganizationForListing)(listingId)(selectedOrganizationId);

  const knownBugRenderIds = [...visibleIds, ...createdIds];

  const feedbackApi = useEntityApi(USER_FEEDBACK);

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const saveFeedback = async (validatedData) => {
    await feedbackApi.post(
      `/api/sendemeldung/organizations/${selectedOrganizationId}/feedback/`,
      validatedData,
    );
  }

  return (
    <SimpleYupFormDialog
      id="feedback"
      title="Feedback &amp; Bugs"
      open={!!open}
      data={feedback}
      onClose={onClose}
      submit={saveFeedback}
      schema={FEEDBACK_SCHEMA}
      saveCaption="Feedback absenden"
      cancelCaption="Schließen"
      maxWidth="xl"
      {...props}
    >
      <DialogContent>
        <Typography gutterBottom>
          Sendemeldung.de befindet sich noch <strong>in Entwicklung</strong>.
        </Typography>
        <Typography gutterBottom>
          In dieser Projektphase erfolgt ein <strong>vorläufiger Betrieb</strong> mit eingeschränkten Funktionen, um
          die fristgerechte Übermittlung von Sendemeldungen im XML-Format zu ermöglichen.
          Insbesondere stehen zu diesem Zeitpunkt noch keine externen Datenquellen zur Verfügung, mit denen fehlende
          Metadaten zu Musikwerken komfortabel recherchiert und nachgetragen werden können.
          Diese Funktionen möchten wir bis Jahresende zur Verfügung stellen.
        </Typography>
        <Typography gutterBottom>
          Damit wir Ihre Anforderungen bei der Entwicklung von Sendemeldung.de berücksichtigen können, sind wir auf
          Ihre Mithilfe angewiesen:
          Bitte teilen Sie uns mit, wenn etwas nicht bzw. nicht wie erwartet funktioniert oder wenn Sie sonstige
          Anregungen haben.
          Wir geben uns größte Mühe, jede Anregung &ndash; in Absprache mit der Steuerungsgruppe der Verbände
          &ndash; zu berücksichtigen.
        </Typography>

        <Collapse in={knownBugRenderIds?.length > 0}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMore/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Bekannte Probleme</Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection: 'column'}}>
              <Typography>
                Damit Sie nichts melden müssen, das bereits bekannt ist, finden Sie nachfolgend eine Liste bekannter
                Anregungen und Bugs, an deren Umsetzung bzw. Behebung wir bereits arbeiten:
              </Typography>

              <Pagination listingId={listingId}/>

              {knownBugRenderIds?.map(getKnownBug).map(({id, title, description}, i) => (
                <Box mt={2} key={id || i}>
                  <Alert severity="info" icon={<BugReport/>}>
                    <AlertTitle>{title}</AlertTitle>
                    {description}
                  </Alert>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Collapse>

        <Paper>
          <Box p={2} mt={2}>
            <Typography variant="h6" className={classes.heading} gutterBottom>Neues Feedback</Typography>
            <Box mb={2}>
              <Alert variant="filled" severity="info">
                Haben Sie einen neuen Bug entdeckt oder sonstige Anregungen oder Hinweise an uns?
                Dann schreiben Sie uns gerne über das folgende Kontaktformular.
              </Alert>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <YupField name="name" variant="outlined"/>
              </Grid>
              <Grid item xs={12}>
                <YupField name="email" variant="outlined"/>
              </Grid>
              <Grid item xs={12}>
                <YupField name="message" variant="outlined"/>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}
