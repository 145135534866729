import participationReducers from './participation-slice';

const reducers = {
  ...participationReducers,
};
export default reducers;

export {default as saga} from './participation-sagas';

export * from './participation-slice';
