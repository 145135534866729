import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: {},
  reducers: {
    preferTokenLoginForm(state, action) {
      state.preferTokenLoginForm = action.payload;
    }
  },
});

export const {preferTokenLoginForm} = preferencesSlice.actions;

export default {
  preferences: preferencesSlice.reducer,
};

export const getPreferencesState = createSelector(
  getUI,
  ui => ui.preferences,
);

export const isTokenLoginFormPreferred = createSelector(
  getPreferencesState,
  preference => preference.preferTokenLoginForm === undefined ? false : preference.preferTokenLoginForm,
)
