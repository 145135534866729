import {all} from 'redux-saga/effects';
import {saga as confirmationSaga} from './confirmation';
import {saga as listingSaga} from './listing';
import {saga as progressSaga} from './progress';
import {saga as unloadBlockerSaga} from './unload-blocker';

export default function* uiSaga() {
  yield all([
    confirmationSaga(),
    listingSaga(),
    progressSaga(),
    unloadBlockerSaga(),
  ]);
}
