import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getOrganizationGetter} from "src/features/entity";

const initialState = {
  organizations: [],
  selectedOrganization: null,
  mainMenuOpen: undefined,
  mainMenuSettingsExpanded: false,

  filesById: {},
  pendingFiles: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setOrganizations(state, action) {
      const {organizations} = action.payload;
      state.organizations = organizations;
    },
    selectOrganization(state, action) {
      const {organization} = action.payload;
      state.selectedOrganization = organization;
    },
    openMainMenu(state, action) {
      state.mainMenuOpen = true;
    },
    closeMainMenu(state, action) {
      state.mainMenuOpen = false;
    },
    setMainMenuSettingsExpanded(state, action) {
      state.mainMenuSettingsExpanded = action.payload;
    },

    uploadFile(state, action) {
      const {internalId, name, size} = action.payload;
      state.filesById[internalId] = {name, size};
      state.pendingFiles.push(internalId);
    },
    uploadedFile(state, action) {
      const {internalId, auth_token, ...other} = action.payload;
      state.filesById[internalId] = other;
      state.pendingFiles = state.pendingFiles.filter((id) => (id !== internalId));
    },
    uploadFailed(state, action) {
      const {internalId} = action.payload;
      state.filesById[internalId] = {
        ...state.filesById[internalId],
        uploadFailed: true,
      };
    },
    updateFile(state, action) {
      const {internalId, ...other} = action.payload;
      state.filesById[internalId] = {
        ...state.filesById[internalId],
        ...other,
      };
    },
  },
});

export const {
  setOrganizations,
  selectOrganization,
  openMainMenu,
  closeMainMenu,
  setMainMenuSettingsExpanded,

  uploadFile,
  uploadedFile,
  updateFile,
  uploadFailed,
} = dashboardSlice.actions;

const reducers = {
  dashboard: dashboardSlice.reducer,
};
export default reducers;

const getDashboardState = (state) => state.dashboard;

export const getMainMenuOpen = createSelector(
  getDashboardState,
  (dashboardState) => dashboardState.mainMenuOpen,
);

export const getMainMenuSettingsExpanded = createSelector(
  getDashboardState,
  (dashboardState) => dashboardState.mainMenuSettingsExpanded,
);

export const getSelectedOrganizationId = createSelector(
  getDashboardState,
  (dashboardState) => dashboardState.selectedOrganization,
);

export const getSelectedOrganization = createSelector(
  getSelectedOrganizationId,
  getOrganizationGetter,
  (selectedOrganizationId, organizationGetter) => (organizationGetter(selectedOrganizationId)),
);

export const getOrganizationIds = createSelector(
  getDashboardState,
  (dashboardState) => dashboardState.organizations,
);

export const getOrganizations = createSelector(
  getOrganizationIds,
  getOrganizationGetter,
  (organizationIds, organizationGetter) => organizationIds.map(organizationGetter),
);

export const getOrganizationsCount = createSelector(
  getOrganizationIds,
  (organizationIds) => organizationIds.length,
);

const getFilesById = createSelector(
  getDashboardState,
  (dashboardState) => dashboardState.filesById,
);

export const getPendingFileInternalIds = createSelector(
  getDashboardState,
  (dashboardState) => dashboardState.pendingFiles,
);

const defaultFile = {};

export const getPendingFile = createSelector(
  getPendingFileInternalIds,
  getFilesById,
  (pendingFileInternalIds, filesById) => (id) => {
    if (!pendingFileInternalIds.includes(id)) {
      return defaultFile;
    }
    return filesById[id] || defaultFile;
  },
);
