import {useFormErrors} from "src/packages/react-hook-form-helpers/react-hook-form-hooks";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import _ from 'lodash';

function extractErrorMessage(errors, maxDepth = 3) {
  if (maxDepth <= 0) {
    return '';
  }

  if (errors) {
    if (errors?.non_field_errors?.message) {
      return errors?.non_field_errors?.message;
    } else if (_.isArray(errors?.non_field_errors)) {
      return errors?.non_field_errors.join(' ');
    } else if (errors?.detail?.message) {
      return errors?.detail?.message;
    } else if (_.isObject(errors)) {
      return Object.values(errors).map(obj => extractErrorMessage(obj, maxDepth - 1)).filter(msg => msg).join(' ');
    }
  }

  return '';
}

export default function FormErrorMessage({renderEmpty = false}) {
  // Render errors. If renderEmpty is set, render an empty, iconless alert if no errors exist.
  const {errors, hasErrors} = useFormErrors();

  if (!hasErrors && !renderEmpty) {
    return null;
  }

  // TODO: Make robust against malformed error objects (e.g., objects as messages).

  return (
    <Alert severity="error" variant="filled" icon={hasErrors ? undefined : false}>
      {hasErrors ? (
        extractErrorMessage(errors) || "Bitte korrigieren Sie Ihre Angaben."
      ) : <>&nbsp;</>}
    </Alert>
  );
}
