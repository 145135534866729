import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {CHANGE_CONTACT_PASSWORD_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {Card, CardContent, DialogContent, Grid} from "@material-ui/core";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {CONTACT} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getContactGetter} from "src/features/entity";
import Typography from "@material-ui/core/Typography";

function ChangePasswordFormDialogContent({username}) {
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mt={2}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Benutzername
              </Typography>
              <strong>{username}</strong>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <YupField name="old_password"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="new_password"/>
        </Grid>
        <Grid item xs={12}>
          <YupField name="passwordConfirmation"/>
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export function ChangePasswordForm({data, onClose, ...props}) {
  const entityApi = useEntityApi(CONTACT);

  const getContact = useSelector(getContactGetter);
  const {username} = getContact(data?.id);

  const saveContact = async (validatedData) => {
    const {id} = validatedData;

    await entityApi.patch(
      `/api/sendemeldung/contacts/${id}/`,
      validatedData,
    );

    // TODO: Generalize save mechanism.
  };

  return (
    <SimpleYupFormDialog
      id={'change-password'}
      title={"Passwort ändern"}
      open={!!data}
      data={data}
      onClose={onClose}
      submit={saveContact}
      schema={CHANGE_CONTACT_PASSWORD_SCHEMA}
      {...props}
    >
      <ChangePasswordFormDialogContent
        data={data}
        username={username}
      />
    </SimpleYupFormDialog>
  );
}
