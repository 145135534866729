import React, {useContext} from "react";
import {FormProvider} from "react-hook-form";

const YupContext = React.createContext(undefined);

export const YupFormProvider = ({children, schema, form}) => {
  return (
    <YupContext.Provider value={schema}>
      <FormProvider {...form}>
        {children}
      </FormProvider>
    </YupContext.Provider>
  );
};

export function useYupContext() {
  return useContext(YupContext);
}
