import {useDispatch} from "react-redux";
import _ from 'lodash';
import {api, AuthenticationError} from "src/api/api";
import {logout} from "src/features/session";
import {normalize} from "normalizr";
import {
  deleteEntity,
  registerEntityObserver,
  setEntities,
  unregisterEntityObserver
} from "src/features/entity/entity-slice";
import {useCallback, useEffect, useState} from "react";

const wrapApiFunc = _.memoize((dispatch) => _.memoize((schema) => _.memoize((apiFunc) => async (url, validatedData, entityOptions = {}, ...args) => {
  const {createEntities, organization} = entityOptions;

  let response;
  try {
    response = await apiFunc(url, validatedData, ...args);
  } catch (e) {
    if (e instanceof AuthenticationError) {
      dispatch(logout());
    }
    throw e;
  }

  let data = response.data;
  let normalizedData = undefined;

  if (data?.next !== undefined && data?.previous !== undefined && data?.results !== undefined) {
    data = data.results;
  }

  if (schema) {
    normalizedData = normalize(data, schema);
    dispatch(setEntities({...normalizedData, created: createEntities, createdForOrganization: organization}));
  }

  return {
    response,
    data,
    normalizedData,
  };
})));

export function getEntityApi(schema, dispatch) {
  return Object.fromEntries(
    Object.entries(api).map(([name, apiFunc]) => [name, wrapApiFunc(dispatch)(schema)(apiFunc)])
  );
}

export function useEntityApi(schema) {
  const dispatch = useDispatch();
  return getEntityApi(schema, dispatch);
  //
  // return Object.fromEntries(
  //   Object.entries(api).map(([name, apiFunc]) => [name, wrapApiFunc(dispatch)(schema)(apiFunc)])
  // );
}

export function useEntityDeleter({entityType, baseUrl}) {
  const dispatch = useDispatch();
  const entityApi = useEntityApi();

  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  const [deletingUuids, setDeletingUuids] = useState(new Set());
  const [deletedUuids, setDeletedUuids] = useState(new Set());
  const [deletionFailedUuids, setDeletionFailedUuids] = useState(new Set());

  const entityDeleter = useCallback(async (id) => {
    setDeletingUuids(new Set(deletingUuids).add(id));

    try {
      try {
        await entityApi.delete(`${baseUrl}${id}/`);
      } catch (e) {
        if (e.response?.status === 404) {
          // This is fine.
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw e;
        }
      }
      dispatch(deleteEntity(entityType, id));
      setDeletedUuids(new Set(deletedUuids).add(id));
    } catch (e) {
      setDeletionFailedUuids(new Set(deletionFailedUuids).add(id));
      throw e;
    } finally {
      let newDeletingUuids = new Set(deletingUuids);
      newDeletingUuids.delete(id);
      setDeletingUuids(newDeletingUuids);
    }

    return true;
  }, [deletingUuids, setDeletingUuids, deletedUuids, setDeletedUuids, deletionFailedUuids, setDeletionFailedUuids, dispatch, entityType, baseUrl]);

  return {
    deletingUuids,
    deletedUuids,
    deletionFailedUuids,
    deleteEntity: entityDeleter,
  };
}

export function useEntityObserver({type, id}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(registerEntityObserver({type, id}));
    return () => {
      dispatch(unregisterEntityObserver({type, id}));
    };
  }, [dispatch, type, id]);
}
