import {navigate} from "gatsby";
import React from "react";
import {Link} from "gatsby-theme-material-ui";

export default function HrefComponent({component: Component, href, children, noOnClick, wrapLink, ...props}) {
  const result = (
    <Component
      href={href}
      onClick={noOnClick ? undefined : (evt) => {
        if (evt.ctrlKey) {
          return;
        }

        evt.preventDefault();
        evt.stopPropagation();

        if (window.location.pathname === href) {
          // Do not add to history.
          navigate(href, {replace: true});
        } else {
          navigate(href);
        }
      }}
      {...props}
    >
      {children}
    </Component>
  );

  if (wrapLink) {
    return (
      <Link
        href={href}
        underline="none"
        variant="inherit"
        color="inherit"
      >
        {result}
      </Link>
    );
  } else {
    return result;
  }
}
