import {attr, fk, many, Model} from 'redux-orm';

export class Organization extends Model {
  toString() {
    return `Organization: ${this.company_name}`;
  }

  static modelName = 'Organization';

  static get fields() {
    return {
      id: attr(),
      company_name: attr(),
      address: attr(),
      type: attr(),
      gemagvlxml_lieferant_id: attr(),
      gemagvlxml_contact: fk('Contact'),
    };
  }
}

export class Contact extends Model {
  toString() {
    return `Contact: ${this.name}`;
  }

  static modelName = 'Contact';

  static get fields() {
    return {
      id: attr(),
      organizations: many({
        to: 'Organization',
        relatedName: 'contacts',
        through: 'OrganizationContactRole',
      }),
      salutation: attr(),
      title: attr(),
      name: attr(),
      email: attr(),
    };
  }
}

export class OrganizationContactRole extends Model {
  toString() {
    return `OrganizationContactRole: ${this.id}`;
  }

  static modelName = 'OrganizationContactRole';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization', 'contactRoles'),
      contact: fk('Contact', 'contactRoles'),
      is_gl: attr(),
      is_technik: attr(),
      is_lira: attr(),
    };
  }
}

export class Station extends Model {
  toString() {
    return `Station: ${this.name}`;
  }

  static modelName = 'Station';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization', 'stations'),
      gemagvl_sender_prg_id: attr(),
      name: attr(),
      gemagvl4_sender: attr(),
      gemagvl4_prognr: attr(),
    };
  }
}

export class UploadedFile extends Model {
  toString() {
    return `UploadedFile: ${this.name}`;
  }

  static modelName = 'UploadedFile';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization', 'uploadedFiles'),
      name: attr(),
      uploaded_at: attr(),
      uploader: attr(),
    };
  }
}

export class GEMAGVL4Lieferung extends Model {
  toString() {
    return `GEMAGVL4Lieferung: ${this.id}`;
  }

  static modelName = 'GEMAGVL4Lieferung';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization'),
      upload: fk('UploadedFile', 'gemagvl4_lieferungen'),
      metadata: attr(),
      errors: attr(),
    };
  }
}

export class GEMAGVL4Ausstrahlung extends Model {
  toString() {
    return `GEMAGVL4Ausstrahlung: ${this.id}`;
  }

  static modelName = 'GEMAGVL4Ausstrahlung';

  static get fields() {
    return {
      id: attr(),
      gemagvl4_lieferung: fk('GEMAGVL4Lieferung'),
      org_music_work: fk('OrgMusicWork'),
      station: fk('Station'),
    };
  }
}

export class ErschieneneTonaufnahme extends Model {
  toString() {
    return `ErschieneneTonaufnahme: ${this.id}`;
  }

  static modelName = 'ErschieneneTonaufnahme';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization'),
      deklaration_tonaufnahme_knz: attr(),
      label: attr(),
      label_code: attr(),
      album_titel: attr(),
      voe_datum: attr(),
      sonstige_info: attr(),
    };
  }
}

export class OrgMusicWork extends Model {
  toString() {
    return `OrgMusicWork: ${this.id}`;
  }

  static modelName = 'OrgMusicWork';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization'),
      musik_titel: attr(),
      titel_sonstige: attr(),
      recht_knz: attr(),
      besetzung_knz: attr(),
      gattung: attr(),
      nutzung_art_knz: attr(),
      aufnahme_datum: attr(),
      prod_titel: attr(),
      prod_ort: attr(),
      musik_herkunft_knz: attr(),
      erschienene_tonaufnahme: fk('ErschieneneTonaufnahme'),
      origin: attr(),
      duplicate_of: fk('OrgMusicWork'),
      duplicate_reason: attr(),
    };
  }
}

export class MusikPerson extends Model {
  toString() {
    return `MusikPerson: ${this.id}`;
  }

  static modelName = 'MusikPerson';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization'),
      musik_person_rolle_knz: attr(),
      name: attr(),
      vorname: attr(),
      org_music_work: fk('OrgMusicWork'),
    };
  }
}

export class MusikProduktionID extends Model {
  toString() {
    return `MusikProduktionID: ${this.id}`;
  }

  static modelName = 'MusikProduktionID';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization'),
      id_typ_knz: attr(),
      id_wert: attr(),
      org_music_work: fk('OrgMusicWork'),
    };
  }
}

export class GEMAGVLXMLLieferung extends Model {
  toString() {
    return `GEMAGVLXMLLieferung: ${this.id}`;
  }

  static modelName = 'GEMAGVLXMLLieferung';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization', 'gemagvlxmlLieferungen'),
      name: attr(),
      created_at: attr(),
      gemagvl4_lieferung: fk('GEMAGVL4Lieferung'),
      metadata: attr(),
      errors: attr(),
    };
  }
}

export class GEMAGVLXMLAusstrahlung extends Model {
  toString() {
    return `GEMAGVLXMLAusstrahlung: ${this.id}`;
  }

  static modelName = 'GEMAGVLXMLAusstrahlung';

  static get fields() {
    return {
      id: attr(),
      gemagvlxml_lieferung: fk('GEMAGVLXMLLieferung'),
      station: fk('Station'),
      datum_uhrzeit_von: attr(),
      datum_uhrzeit_bis: attr(),
      musik_dauer: attr(),
      org_music_work: fk('OrgMusicWork'),
      errors: attr(),
      gemagvl4_ausstrahlung: fk('GEMAGVL4Ausstrahlung'),
    };
  }
}

export class UserFeedback extends Model {
  toString() {
    return `UserFeedback: ${this.id}`;
  }

  static modelName = 'UserFeedback';

  static get fields() {
    return {
      id: attr(),
      organization: fk('Organization'),
      created_at: attr(),
      creator: attr(),
      name: attr(),
      email: attr(),
      message: attr(),
    };
  }
}

export class KnownBug extends Model {
  toString() {
    return `KnownBug: ${this.id}`;
  }

  static modelName = 'KnownBug';

  static get fields() {
    return {
      id: attr(),
      created_at: attr(),
      title: attr(),
      description: attr(),
      type: attr(),
    };
  }
}

export class TodoTask extends Model {
  toString() {
    return `TodoTask: ${this.id}`;
  }

  static modelName = 'TodoTask';

  static get fields() {
    return {
      id: attr(),
      todo_type: attr(),
      metadata: attr(),
      child_tasks: fk('TodoTask'),
    };
  }
}
