import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Controller} from "react-hook-form";
import React from "react";
import _ from "lodash";

export default function Choices(
  {
    errors = {},
    control,
    name,
    variant,
    fullWidth = true,
    label,
    items = {},
    margin,
    onBlur,
    ...props
  },
) {
  const error = _.get(errors, name);

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      margin={margin}
      error={!!error}
      onBlur={onBlur}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({field}) => (
          <Select
            label={label}
            inputProps={{
              id: name,
            }}
            {...props}
            {...field}
          >
            {Object.entries(items).map(([value, key]) => (
              <MenuItem value={value} key={value}>{key}</MenuItem>
            ))}
          </Select>
        )}
      />
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  );
}
