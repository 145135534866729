import {
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import React, {useEffect} from "react";
import {
  Album,
  Business,
  CreditCard,
  Dashboard as DashboardIcon,
  ExpandLess,
  ExpandMore,
  Folder,
  Notifications,
  People,
  PlaylistPlay, Publish,
  Radio,
  Security,
  Settings,
  Timelapse,
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {
  getMainMenuSettingsExpanded,
  getSelectedOrganization,
  setMainMenuSettingsExpanded
} from "src/features/dashboard";
import HrefMenuListItem from "src/packages/gatsby-mui-helpers/HrefMenuListItem";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  active: {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

export function MenuListItem({active, children, className, ...props}) {
  const classes = useStyles();

  return (
    <ListItem
      {...props}
      className={active ? clsx(classes.active, className) : className}
    >
      {children}
    </ListItem>
  );
}

export default function DrawerMainMenu({expandSettings, selected}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {org_music_works_count} = useSelector(getSelectedOrganization);

  // Manage settings expansion in redux; open submenu once when expandSettings is set.
  const settingsExpanded = useSelector(getMainMenuSettingsExpanded);
  useEffect(() => {
    if (!settingsExpanded && expandSettings) {
      dispatch(setMainMenuSettingsExpanded(!settingsExpanded));
    }
  }, [expandSettings])

  const handleSettingsClick = () => {
    dispatch(setMainMenuSettingsExpanded(!settingsExpanded));
  };

  return (
    <>
      <List>
        <HrefMenuListItem
          button
          href={'/dashboard/'}
          active={selected === 'tasks'}
        >
          <ListItemIcon><DashboardIcon/></ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </HrefMenuListItem>
        <HrefMenuListItem
          button
          href={'/dashboard/import/'}
          active={selected === 'import'}
        >
          <ListItemIcon><Publish/></ListItemIcon>
          <ListItemText primary="Hochgeladene Dateien"/>
        </HrefMenuListItem>
        <HrefMenuListItem
          button
          href={'/dashboard/notifications/'}
          active={selected === 'notifications'}
        >
          <ListItemIcon><Notifications/></ListItemIcon>
          <ListItemText primary="Benachrichtigungen"/>
        </HrefMenuListItem>
      </List>
      <Divider/>
      <List>
        <HrefMenuListItem button href={'/dashboard/reports/'} active={selected === 'reports'}>
          <ListItemIcon><PlaylistPlay/></ListItemIcon>
          <ListItemText primary="Sendemeldungen"/>
        </HrefMenuListItem>
        <HrefMenuListItem button href={'/dashboard/archive/'} active={selected === 'database'}>
          <ListItemIcon><Album/></ListItemIcon>
          <ListItemText primary="Musikdatenbank"/>
          <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/archive/'}>
            {org_music_works_count ? (
              <Chip size="small" label={org_music_works_count} align="right" style={{cursor: 'pointer'}}/>
            ) : null}
          </HrefComponent>
        </HrefMenuListItem>
      </List>
      <Divider/>
      <List>
        <MenuListItem button onClick={handleSettingsClick}>
          <ListItemIcon><Settings/></ListItemIcon>
          <ListItemText primary="Einstellungen"/>
          {settingsExpanded ? <ExpandLess/> : <ExpandMore/>}
        </MenuListItem>

        <Collapse in={settingsExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <HrefMenuListItem button className={classes.nested} href={'/dashboard/organization/'}
                              active={selected === 'organization'}>
              <ListItemIcon>
                <Business/>
              </ListItemIcon>
              <ListItemText primary="Stammdaten"/>
            </HrefMenuListItem>
            <HrefMenuListItem button className={classes.nested} href={'/dashboard/programmes/'}
                              active={selected === 'programmes'}>
              <ListItemIcon>
                <Radio/>
              </ListItemIcon>
              <ListItemText primary="Programme"/>
            </HrefMenuListItem>
            <HrefMenuListItem button className={classes.nested} href={'/dashboard/people/'}
                              active={selected === 'people'}>
              <ListItemIcon>
                <People/>
              </ListItemIcon>
              <ListItemText primary="Personen"/>
            </HrefMenuListItem>
            <HrefMenuListItem button className={classes.nested} href={'/dashboard/security/'}
                              active={selected === 'security'}>
              <ListItemIcon>
                <Security/>
              </ListItemIcon>
              <ListItemText primary="Sicherheit"/>
            </HrefMenuListItem>
            <HrefMenuListItem button className={classes.nested} href={'/dashboard/billing/'}
                              active={selected === 'billing'}>
              <ListItemIcon><CreditCard/></ListItemIcon>
              <ListItemText primary="Abrechnung"/>
            </HrefMenuListItem>
          </List>
        </Collapse>

        <HrefMenuListItem button href={'/dashboard/documents/'} active={selected === 'documents'}>
          <ListItemIcon><Folder/></ListItemIcon>
          <ListItemText primary="Vertragsdokumente"/>
        </HrefMenuListItem>
      </List>
      <Divider/>
      <List>
        <HrefMenuListItem button href={'/dashboard/timeline/'} active={selected === 'timeline'}>
          <ListItemIcon><Timelapse/></ListItemIcon>
          <ListItemText primary="Projektstatus"/>
        </HrefMenuListItem>
      </List>
    </>
  );
}
