import {ORM} from 'redux-orm';
import {
  Contact,
  ErschieneneTonaufnahme,
  GEMAGVL4Ausstrahlung,
  GEMAGVL4Lieferung, GEMAGVLXMLAusstrahlung, GEMAGVLXMLLieferung, KnownBug, MusikPerson, MusikProduktionID,
  Organization, OrganizationContactRole,
  OrgMusicWork,
  Station,
  UploadedFile, UserFeedback, TodoTask
} from './models';

const orm = new ORM({
  stateSelector: state => state.orm,
});
orm.register(
  Organization,
  Contact,
  OrganizationContactRole,
  Station,
  UploadedFile,
  GEMAGVL4Lieferung,
  GEMAGVL4Ausstrahlung,
  ErschieneneTonaufnahme,
  OrgMusicWork,
  MusikPerson,
  MusikProduktionID,
  GEMAGVLXMLLieferung,
  GEMAGVLXMLAusstrahlung,
  UserFeedback,
  KnownBug,
  TodoTask,
);

export default orm;
