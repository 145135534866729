import React from "react";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import {MenuListItem} from "src/components/dashboard/components/drawer-main-menu-view";

export default function HrefMenuListItem({href, ...props}) {
  return (
    <HrefComponent
      wrapLink
      component={MenuListItem}
      href={href}
      {...props}
    />
  );
}
