import {schema} from 'normalizr';

const createEntity = (name, schemaDefinition, options) => (new schema.Entity(name, schemaDefinition, options));

export const CONTACT = createEntity('contacts');
export const CONTACTS = [CONTACT];

export const USER = createEntity('users');

export const CONTACT_ROLE = createEntity('contact_roles', {
  contact: CONTACT,
});
export const CONTACT_ROLES = [CONTACT_ROLE];

export const STATION = createEntity('stations', {
  gemagvlxml_contact: CONTACT,
});
export const STATIONS = [STATION];

export const ORGANIZATION = createEntity('organizations', {
  contacts: CONTACTS,
  contact_roles: CONTACT_ROLES,
  stations: STATIONS,
});
export const ORGANIZATIONS = [ORGANIZATION];

export const TODO_TASK = createEntity('todo_tasks');
export const TODO_TASKS = [TODO_TASK];

export const MUSIK_PERSON = createEntity('musik_personen');
export const MUSIK_PERSONEN = [MUSIK_PERSON];

export const MUSIK_PRODUKTION_ID = createEntity('musik_produktion_ids');
export const MUSIK_PRODUKTION_IDS = [MUSIK_PRODUKTION_ID];

export const ERSCHIENENE_TONAUFNAHME = createEntity('erschienene_tonaufnahmen');
export const ERSCHIENENE_TONAUFNAHMEN = [ERSCHIENENE_TONAUFNAHME];

export const ORG_MUSIC_WORK = createEntity('org_music_works', {
  musik_personen: MUSIK_PERSONEN,
  musik_produktion_ids: MUSIK_PRODUKTION_IDS,
  erschienene_tonaufnahme: ERSCHIENENE_TONAUFNAHME,
  todo_tasks: TODO_TASKS,
});
export const ORG_MUSIC_WORKS = [ORG_MUSIC_WORK];

export const UPLOADED_FILE = createEntity('uploaded_files');
export const UPLOADED_FILES = [UPLOADED_FILE];

export const GEMAGVL4_LIEFERUNG = createEntity('gemagvl4_lieferungen',
{
  upload: UPLOADED_FILE,
  organization: ORGANIZATION,
  stations: STATIONS,
});
export const GEMAGVL4_LIEFERUNGEN = [GEMAGVL4_LIEFERUNG];

export const GEMAGVL4_AUSSTRAHLUNG = createEntity('gemagvl4_ausstrahlungen', {
  gemagvl4_lieferung: GEMAGVL4_LIEFERUNG,
  todo_tasks: TODO_TASKS,
});
export const GEMAGVL4_AUSSTRAHLUNGEN = [GEMAGVL4_AUSSTRAHLUNG];

export const GEMAGVLXML_LIEFERUNG = createEntity('gemagvlxml_lieferungen', {
  upload: UPLOADED_FILE,
  gemagvl4_lieferung: GEMAGVL4_LIEFERUNG,
  organization: ORGANIZATION,
  stations: STATIONS,
  todo_tasks: TODO_TASKS,
});
export const GEMAGVLXML_LIEFERUNGEN = [GEMAGVLXML_LIEFERUNG];

export const KNOWN_BUG = createEntity('known_bugs');
export const KNOWN_BUGS = [KNOWN_BUG];

export const USER_FEEDBACK = createEntity('user_feedback_pieces', {
  organization: ORGANIZATION,
});
export const USER_FEEDBACKS = [USER_FEEDBACK];

export const GEMAGVLXML_AUSSTRAHLUNG = createEntity('gemagvlxml_ausstrahlungen', {
  org_music_work: ORG_MUSIC_WORK,
  gemagvlxml_lieferung: GEMAGVLXML_LIEFERUNG,
  gemagvl4_ausstrahlung: GEMAGVL4_AUSSTRAHLUNG,
  todo_tasks: TODO_TASKS,
});
export const GEMAGVLXML_AUSSTRAHLUNGEN = [GEMAGVLXML_AUSSTRAHLUNG];

export const ENTITY_TYPES = {
  org_music_work: ORG_MUSIC_WORK,
  org_music_works: ORG_MUSIC_WORKS,
  gemagvlxml_lieferung: GEMAGVLXML_LIEFERUNG,
  gemagvlxml_ausstrahlung: GEMAGVLXML_AUSSTRAHLUNG,
  gemagvl4_lieferung: GEMAGVL4_LIEFERUNG,
  gemagvl4_ausstrahlung: GEMAGVL4_AUSSTRAHLUNG,
  uploaded_file: UPLOADED_FILE,
  organization: ORGANIZATION,
  station: STATION,
  contact_role: CONTACT_ROLE,
  known_bug: KNOWN_BUG,
  todo_task: TODO_TASK,
};

export const ENTITY_LIST_URLS = {
  todo_task: '/api/sendemeldung/organizations/{organizationId}/todo_tasks/',
  gemagvlxml_lieferung: '/api/sendemeldung/organizations/{organizationId}/gemagvlxml_lieferungen/',
  gemagvl4_lieferung: '/api/sendemeldung/organizations/{organizationId}/gemagvl4_lieferungen/',
  gemagvlxml_ausstrahlung: '/api/sendemeldung/organizations/{organizationId}/gemagvlxml_ausstrahlungen/',
  gemagvl4_ausstrahlung: '/api/sendemeldung/organizations/{organizationId}/gemagvl4_ausstrahlungen/',
  uploaded_file: '/api/sendemeldung/organizations/{organizationId}/uploaded_files/',
  org_music_work: '/api/sendemeldung/organizations/{organizationId}/org_music_works/',
  station: '/api/sendemeldung/organizations/{organizationId}/stations/',
  contact_role: '/api/sendemeldung/organizations/{organizationId}/contact_roles/',
  organization: '/api/sendemeldung/organizations/',
};
